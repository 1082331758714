import { defineStore } from 'pinia'

export const UseAuthAdminStore = defineStore({
    id: "UseAuthAdminStore",
    state: () => ({
        me: {
            id: null,
            username: null,
            email: null,
            fullname: null,
            whatsapp: null,
            about: null,
            status: null,
            avatar: null,
            role: null,
            created_at: null,
            updated_at: null,
            meta: null,
            token: null,
        },
        load: false,
        relogin: false,
    }),
    getters: {
        loading: (state) => state.load,
        meValue: (state) => state.me,
    },
    actions: {
        async GetMe() {
            const { data, status } = await RFetch(`/system/auth/me`);
            if (status === "error") {
                this.relogin = true;
            } else {
                this.relogin = false;
            }
            if (data) {
                if (data?.username === null) {
                    window.location.href = "/admin/logout";
                }
                this.me = data;
            }
        },
    },
});
